<template>
  <span>
    <p>
      <span class="secondary--text">{{ title }}</span>
      {{ formattedBody }}
      <a v-if="this.text?.length > splitNum" v-bind:class="($vuetify.theme.dark) ? 'anchorDark' : 'anchorlight'"
        @click="showingFullText = !showingFullText">
        Read {{ showingFullText ? "Less" : "More" }}
      </a>
    </p>
  </span>
</template>

<script>
export default {
  props: {
    text: String,
    splitNum: Number,
    title: String
  },

  data() {
    return {
      showingFullText: false,
    };
  },
  mounted() {
  },

  computed: {
    formattedBody() {
      if (this.showingFullText) {
        return this.text;
      }

      return `${this.text.slice(0, this.splitNum).trim()}`;
    }
  }
};
</script>